import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import './AutoManualJoin.css'
import BackImg from './../Public/Image/Login/Back.svg'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom';
// import { setTelegramAccount } from './../app/JoinState'
import { Link } from 'react-router-dom';
import { TelegramDB } from './../DataBase/TelegramDB'
import { GetCookie } from './../Action/GetCookie'
// import { GetChannels } from './../CoinApi/GetChannels'
import { AllApi } from './../Api/AllApi'

// import { ReportChannelAPI } from './ReportChannel'

import { API_ID, API_HASH } from './../Const'
import { StringSession } from 'telegram/sessions'
import { TelegramClient, Api } from 'telegram'
import Snackbar from '@mui/material/Snackbar';
import { BottomSheet } from 'react-spring-bottom-sheet'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import Skeleton from '@mui/material/Skeleton';
import ReportOutlinedIcon from '@mui/icons-material/ReportOutlined';
import More from './../Public/Image/Join/more.svg'
import DoubleCoinYellow from './../Public/Image/Join/DoubleCoinYellow.svg'
import DoubleCoinGrey from './../Public/Image/Join/DoubleCoinGrey.svg'
import { useHistory } from 'react-router-dom';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import AutoJoinNormal from './../Public/Image/Join/AutoJoinNormal.svg'
import AutoJoinDisable from './../Public/Image/Join/AutoJoinDisable.svg'
import AutoJoinInFlood from './../Public/Image/Join/AutoJoinInFlood.svg'
import FirstJoinImg from './../Public/Image/Join/FirstJoin.svg'
import JoinSpeed from './../Public/Image/Join/JoinSpeed.svg'
import JoinStop from './../Public/Image/Join/JoinStop.svg'
import Danger from './../Public/Image/Join/Danger.svg'
import Filled from './../Public/Image/Join/Filled.svg'
import TooMuchLeftIcon from './../Public/Image/Join/TooMuchLeft.svg'
import { setUserCoin } from './../app/Home'
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { SwipeableDrawer } from '@mui/material';
import Skip from './../Public/Image/Join/Skip.svg'
import skipDisable from './../Public/Image/Join/skipDisable.svg'
import NoInternet from './../Public/Image/Join/NoInternet.svg'
import Limit from './../Public/Image/Join/Limit.svg'
import WifiOffIcon from '@mui/icons-material/WifiOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LoadingBig from './../Loading'
import DangerBottomSheet from './../Public/Image/Join/DangerBottomSheet.svg'
import { useLiveQuery } from "dexie-react-hooks";
import { SetChannelTitle } from './../app/JoinState'
import { SetChannelUsername } from './../app/JoinState'
import { SetChannelTGID } from './../app/JoinState'
import { SetChannelImage } from './../app/JoinState'
// import { setAutoJoinLocal } from './../app/JoinState'
import LogOutIcon from './../Public/Image/Join/icons8_Logout 1.svg'
import { ManualJoin } from './ManualJoin'
// import { AutoJoinClass } from './AutoJoinClass'
// import { Check, Speed } from '@mui/icons-material'
import BigCoin from './../Public/Image/Join/BigCoinold (2).svg'
import { CheckConnection } from './CheckConnection'
import { JoinChannel } from './../CoinApi/JoinChannel'
import ReactCountryFlag from "react-country-flag"
import { SetJoinCountRedux } from './../app/Game'
import GameCoin from './GameCoin/GameCoin'

const AutoManualJoin = () => {
    const JoinCountRedux = useSelector((state) => state.Game.JoinCountRedux)
    console.log(JoinCountRedux)
    const dispatch = useDispatch()
    const location = useLocation();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClickmenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClosemenu = () => {
        setAnchorEl(null);
    };
    const ShareChannelLink = (link) => {
        if (navigator.share) {
            //  alert("Congrats! Your browser supports Web Share API");
            navigator
                .share({
                    url: link,
                    text: link,
                    title: link,
                })
                .then(() => {
                    //  alert("Sharing successfull");
                })
                .catch(() => {
                    //  alert("Sharing failed");
                });
        } else {
            //    alert("Sorry! Your browser does not support Web Share API");
        }
    }
    // logoutmenu
    const [LogOutMenu, setLogOutMenu] = React.useState(null);

    const handleLogOutMenu = (event) => {
        setLogOutMenu(event.currentTarget);
    };
    const handleCloseLogOutMenu = () => {
        setLogOutMenu(null);
    };
    // logoutmenu

    const [ReportSnackbar, setReportSnackbar] = useState(false)
    const ReportChannelfunc = async (obj, id, token) => {
        // console.log(1)
        // console.log(obj, id)
        // .username,id:ListToShow[0].tgChannelId
        // reportchannel
        // let message='Thank you for your report'
        // "username": obj.username,
        // "tgChannelId": obj.tgChannelId,
        // let report = await ReportChannelAPI(obj, id, token);


        const MakeApiClass = new AllApi();
        let report = await MakeApiClass.ReportChannelAPI(obj, id, token);


        // console.log(report)
        if (report.status == 'success') {
            //   GetSnackbar('Thank you for your report')
            setReportSnackbar(true)
            // handleClick({ vertical: 'bottom', horizontal: 'right' })}
            // const MyAccount = new AutoManualJoinObject(UserState.Session, UserState.ListForJoin, UserState.User.username, UserState.id);

            const MyAccount = Object.create(AutoManualJoinObject);
            MyAccount.ListForJoin = UserState.ListForJoin
            MyAccount.Session = UserState.Session
            // MyAccount.ListForJoin = ListForJoin
            MyAccount.UserName = UserState.UserName
            MyAccount.ID = UserState.id
            MyAccount.Next(UserState.ListForJoin)
        } else if (report.code == 120 || report.code == 121 || report.code == 122 || report.code == 123) {
            let name = "Token"
            let name1 = "active"
            document.cookie = name + '=; Max-Age=-99999999;';
            document.cookie = name1 + '=; Max-Age=-99999999;';
            history.push("/");
            // dispatch(setLoading(false))

        } else {
            //   GetSnackbar('Somthing went wrong')
            setSnackbarLogout(true)
        }
        // 
    }
    const searchParams = new URLSearchParams(location.search);
    const ID = searchParams.get('id');
    let Session;
    const TelegramAccount = useSelector((state) => state.JoinState.TelegramAccount)
    const ChannelTitle = useSelector((state) => state.JoinState.ChannelTitle)
    const ChannelUsername = useSelector((state) => state.JoinState.ChannelUsername)
    const ChannelTGID = useSelector((state) => state.JoinState.ChannelTGID)
    const ChannelImage = useSelector((state) => state.JoinState.ChannelImage)
    // const AutoJoinLocal = useSelector((state) => state.JoinState.AutoJoinLocal)
    const [UserState, setUserState] = useState(null)
    const [StartManualJoin, setStartManualJoin] = useState(false)
    const [Loading, setLoading] = useState(false)
    const [RemoveNumber, setRemoveNumber] = useState(false)
    const [LogOutState, setLogOutState] = useState(false)
    const UsersDB = useLiveQuery(() => TelegramDB.Data.toArray());
    const [MyClient, setMyClient] = useState(null)
    const [FirstJoinState, setFirstJoinState] = useState(false)
    const onDismissFirstJoin = () => {
        setTimeout(() => {
            setFirstJoinState(false)
        }, 300);
    }
    const [Connection, setConnection] = useState(null)
    const [AutoJoinState, setAutoJoinState] = useState(false)
    async function connectWithTimeout(Session) {
        // console.log(navigator.onLine ? "Online" : "OFFline");

        localStorage.setItem('NewSession', Session)
        const stringSession = new StringSession(localStorage.getItem('NewSession'));
        const Client = new TelegramClient(stringSession, Number(API_ID), API_HASH, {
            connectionRetries: 3,
        });
        Client.setLogLevel("none");

        const connectPromise = await Client.connect();
        // console.log(connectPromise)
        const timeoutPromise = new Promise((resolve, reject) => {
            // setTimeout(() => {
            reject(false);
            // }, 10);
        });
        // console.log(Client)
        setMyClient(Client)
        return Promise.race([connectPromise, timeoutPromise, Client]);
    }

    async function checkConnection1(Session, ItemID) {
        let ConnectionStatus;
        // setConnection(null)


        if (navigator.onLine) {



            // console.log('online')

            try {
                const connectRes = await connectWithTimeout(Session);
                // console.log('Connection successful:', connectRes);

                if (connectRes == undefined || connectRes == true) {
                    //  console.log(connectRes)
                    setConnection(false)
                    ConnectionStatus = false
                } else {
                    setConnection(true)
                    ConnectionStatus = true

                }
                // console.log(ConnectionStatus)
            } catch (error) {
                // console.log(MyClient)
                console.log('Connection failed:', error);
                if (error.errorMessage == "AUTH_KEY_DUPLICATED") {
                    // const id = await TelegramDB.Data.delete(ItemID);
                    let res = await TelegramDB.Data.where({ id: Number(ItemID) }).modify({ Session: false })
                    // console.log(ItemID)
                    history.push(`/Coin?Status=Free`);
                }
                ConnectionStatus = true
                setConnection(true)
            }
        } else {
            // console.log('offline')
            setConnection(true)
            ConnectionStatus = true
        }

        return ConnectionStatus
    }
    // console.log(Connection)
    // console.log(AutoJoinState)
    const [FloodWaitState, setFloodWaitState] = useState(false)

    useEffect(() => {
        if (UsersDB) {
            UsersDB.map(item => {
                if (item.id === Number(ID)) {
                    setUserState(item)
                    // console.log(item)
                    // console.log(MyClient)
                    if (MyClient == null) {

                        checkConnection1(item.Session, item.id)
                        CheckLimit(item)
                        CheckAutoJoin(item)
                    }
                }
            })
        }
    }, [UsersDB])

    const [Hour, setHour] = useState(0)
    const [Minute, setMinute] = useState(0)
    const [Second, setSecond] = useState(0)
    const RemoveFloodWait = async () => {
        try {
            // console.log("flood wait 259")
            let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: 0 })

        } catch (error) {
            // console.log(error)
        }
        return true
    }
    let timer1
    const timer = async (limit, item, status) => {
        // console.log(limit, item)
        let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
        var sec = limit;
        window.timer1 = setInterval(function () {
            // console.log(status)
            if (status == 'start') {
                // console.log(sec)
                sec--;
                if (sec <= 0) {

                    clearInterval(window.timer1)

                    // console.log('end')
                    setFloodWaitState(false)
                    let Accountlimit;

                    TelegramAccountUpdatelimit.map(item1 => {
                        if (item1.id === item.id) {
                            Accountlimit = item1
                        }
                    })
                    setFloodWaitState(false)


                    RemoveFloodWait()
                    CheckAutoJoin(Accountlimit)
                    GetDetails(Accountlimit.Session, Accountlimit.ListForJoin, Accountlimit.FloodWait)
                    return sec = true
                } else {
                    localStorage.setItem("limit" + item.id, sec)
                    // console.log(sec)
                    secondsToHms(sec)
                    return sec
                }
            } else {
                clearInterval(timer1)

            }


        }, 1000);


        return sec
    }

    function secondsToHms(d) {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        m = String(m).padStart(2, "0");
        h = String(h).padStart(2, "0");
        s = String(s).padStart(2, "0");

        setHour(h)
        setMinute(m)
        setSecond(s)
    }
    const GetUpdatedTelegramAccont = async () => {
        let TelegramAccountUpdate = await TelegramDB.Data.toArray()
        // setTelegramAccount(TelegramAccountUpdate)
        return TelegramAccountUpdate
    }
    const CheckLimit = async (item1) => {
        let item
        let TelegramAccont = await GetUpdatedTelegramAccont()
        TelegramAccont.map(TelegramAccontupdate => {
            if (TelegramAccontupdate.id == item1.id) {
                item = TelegramAccontupdate
            }
        })

        let JoinTimeout;
        let res
        const JoinPromise1 = new Promise((resolve, reject) => {
            resolve(CheckConnection(item.Session, item.id));
            clearTimeout(JoinTimeout)
        });
        const JoinPromise2 = new Promise((resolve, reject) => {
            JoinTimeout = setTimeout(resolve, 10000, 'errorConnection');
        });
        res = await Promise.race([JoinPromise1, JoinPromise2])
        // console.log(res)
        let Status = null
        if (res.status == true || res.status == undefined) {
            // setConnection(false)
            setMyClient(res.Client)
            let Now = Date.now()
            if (item.FloodWait) {


                if (item.FloodWait != null) {

                    if (Now < item.FloodWait) {

                        setFloodWaitState(true)
                        // clearInterval(timer1)
                        // timer('LimitTimeMiliSecond', 'item', 'stop')
                        if (window.timer1) clearInterval(window.timer1)

                        let LimitTimeMiliSecond = item.FloodWait - Now
                        LimitTimeMiliSecond = LimitTimeMiliSecond / 1000
                        // console.log(LimitTimeMiliSecond)
                        Status = true
                        // console.log("Retry Timer")
                        let tres = timer(LimitTimeMiliSecond, item, 'start')
                        // console.log(tres)




                    } else {
                        setFloodWaitState(false)
                        Status = false

                        try {
                            // console.log("flood wait 000000000000")
                            let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: 0 })

                        } catch (error) {
                            // console.log(error)
                        }

                    }
                } else {
                    Status = false
                }
            } else {
                Status = false
            }


            let speed
            if (item.SpeedJoin) {
                if (item.SpeedJoin.Status == 'Slowly') {
                    setSpeedState('Slowly')
                    let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
                    speed = random + 2
                } else if (item.SpeedJoin.Status == 'Medium') {
                    setSpeedState('Medium')
                    let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
                    speed = random + 1
                } else if (item.SpeedJoin.Status == 'Quick') {
                    setSpeedState('Quick')
                    let random = Math.floor(Math.random() * (4 - 0 + 1) + 0)
                    speed = random + 0
                } else if (item.SpeedJoin.Status == 'Desired') {
                    if (item.SpeedJoin.SpeedOfJoin == 1) {
                        speed = 5
                    } else if (item.SpeedJoin.SpeedOfJoin == 2) {
                        speed = 10
                    } else if (item.SpeedJoin.SpeedOfJoin == 3) {
                        speed = 20
                    } else if (item.SpeedJoin.SpeedOfJoin == 4) {
                        speed = 30
                    } else if (item.SpeedJoin.SpeedOfJoin == 5) {
                        speed = 40
                    } else if (item.SpeedJoin.SpeedOfJoin == 6) {
                        speed = 50
                    } else if (item.SpeedJoin.SpeedOfJoin == 7) {
                        speed = 60
                    }
                    setSpeedState(speed)
                }
            } else {
                let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
                speed = random + 0
            }
        } else {
            // console.log('error connection')
            setConnection(true)

        }


        return Status

    }
    const CheckAutoJoin = async (item1) => {
        let item
        let TelegramAccont = await GetUpdatedTelegramAccont()
        TelegramAccont.map(TelegramAccontupdate => {
            if (TelegramAccontupdate.id == item1.id) {
                item = TelegramAccontupdate
            }
        })
        // console.log(item)

        if (item.AutoJoin == true) {

            try {
                let res = await TelegramDB.Data.where({ id: Number(item.id) }).modify({ AutoJoin: false })
            } catch (error) {
                // console.log(error)
            }
            try {
                let res = await TelegramDB.Data.where({ id: Number(item.id) }).modify({ AutoJoinSingle: true })
            } catch (error) {
                // console.log(error)
            }
            AutoJoinStart(12)

        } else if (item.AutoJoinSingle == true) {
            AutoJoinStart(1)
        }
    }
    let Token = GetCookie('Token');
    // get details && client
    useEffect(() => {
        // console.log(MyClient)
        if (UserState) {
            // console.log(UserState)
            if (!UserState.ListForJoin) {
                // console.log(1)
                GetListFromApi(Token)
            } else {
                if (UserState.ListForJoin.length == 0) {
                    GetListFromApi(Token)
                } else {
                    // console.log(UserState.ListForJoin[0])

                    if (UserState.ListForJoin[0].username != ChannelUsername) {
                        // console.log(MyClient)
                        if (MyClient != null) {
                            // console.log(MyClient)
                            GetDetails(UserState.Session, UserState.ListForJoin, UserState.FloodWait)
                        } else {
                            // console.log(MyClient)
                        }

                    } else {
                    }
                }

            }
        }
    }, [UserState, MyClient])
    // get details && client

    const [TooMuchLeft, setTooMuchLeft] = useState(false)
    const GetListFromApi = async (Token) => {
        if (UserState) {
            if (UserState.BotSession) {
                Session = UserState.Session
                let Token = GetCookie('Token');

                // console.log(Token, UserState.BotSession)
                // let res = await GetChannels(Token, UserState.BotSession)
                const MakeApiClass = new AllApi();
                let res = await MakeApiClass.GetChannels(Token, UserState.BotSession)
                // console.log(res)
                if (res.data == null) {
                    return false
                } else if (res.data.limit) {
                    if (res.data.limit == 'limit') {

                        setTooMuchLeft(true)
                    }
                } else {
                    if (res.code == 200) {
                        AddTelegramSession(res.data.data)
                        return true
                    } else if (res.code == 120 || res.code == 121 || res.code == 122 || res.code == 123) {
                        let name = "Token"
                        let name1 = "active"
                        document.cookie = name + '=; Max-Age=-99999999;';
                        document.cookie = name1 + '=; Max-Age=-99999999;';
                        history.push("/");
                        // dispatch(setLoading(false))

                    }
                }

            }
        }

    }
    const GetDetails = async (Session, List, FloodWait) => {
        // console.log(FloodWait)
        if (FloodWait > 0) {
            setFloodWaitState(true)
        } else {
            setFloodWaitState(false)

            setLoading(true)
            if (List.length == 0) {
                let Token = GetCookie('Token');
                GetListFromApi(Token)
            } else {
                // console.log(List[0].username)
                let username = List[0].username
                try {

                    // console.log(MyClient)
                    let JoinTimeout;
                    let res
                    const JoinPromise1 = new Promise((resolve, reject) => {
                        resolve(CheckConnection(UserState.Session, UserState.id));
                        clearTimeout(JoinTimeout)
                    });
                    const JoinPromise2 = new Promise((resolve, reject) => {
                        JoinTimeout = setTimeout(resolve, 10000, 'errorConnection');
                    });
                    res = await Promise.race([JoinPromise1, JoinPromise2])
                    // console.log(res)
                    if (res.status == true || res.status == undefined) {
                        // console.log(UserState)
                        // console.log(MyClient)
                        const resconnect = await MyClient.connect()
                        //    console.log(resconnect)
                        // const uu = await MyClient.invoke(
                        //     new Api.users.GetFullUser({
                        //         id: UserState.User.username,
                        //     }) 
                        // );
                        // console.log(uu);
                        // const result = await MyClient.invoke(
                        //     new Api.channels.GetFullChannel({
                        //         channel: username,
                        //     })
                        // );
                        let JoinTimeout1;
                        let res1
                        let result
                        const getchannel = new Promise((resolve, reject) => {
                            resolve(MyClient.invoke(
                                new Api.channels.GetFullChannel({
                                    channel: username,
                                })
                            ));
                            clearTimeout(JoinTimeout1)
                        });
                        // console.log(getchannel)
                        // const getchannel = new Promise((resolve, reject) => {
                        //     resolve(MyClient.invoke(new Api.channels.GetFullChannel({ channel: username, }))
                        //     clearTimeout(JoinTimeout)
                        // });
                        const getchannel2 = new Promise((resolve, reject) => {
                            JoinTimeout1 = setTimeout(resolve, 15000, 'errorConnection');
                        });
                        result = await Promise.race([getchannel, getchannel2])


                        // console.log(result)
                        if (result != 'errorConnection') {
                            // console.log(result.chats[0].id.value)
                            // console.log(typeof (result.chats[0].id.value))
                            let id = result.chats[0].id.value.toString();
                            id = id.replace('n', '')
                            dispatch(SetChannelTitle(result.chats[0].title))
                            dispatch(SetChannelUsername(result.chats[0].username))
                            dispatch(SetChannelTGID(id))
                            const buffer = await MyClient.downloadProfilePhoto(result.chats[0])
                            const blob = new Blob([buffer], { type: 'text/plain' });
                            const reader = new FileReader();
                            reader.onload = function () {
                                const base64String = reader.result;
                                dispatch(SetChannelImage(base64String))
                            };
                            reader.readAsDataURL(blob);
                        } else {
                            // console.log('error get channel')
                            const MyAccount = Object.create(AutoManualJoinObject);
                            MyAccount.ListForJoin = UserState.ListForJoin
                            MyAccount.Session = UserState.Session
                            // MyAccount.ListForJoin = ListForJoin
                            MyAccount.UserName = UserState.UserName
                            MyAccount.ID = UserState.id
                            MyAccount.Next(UserState.ListForJoin)
                        }
                        setLoading(false)
                    }



                } catch (error) {
                    // console.log(error)
                    if (error.stack.indexOf('No user has') >= 0) {
                        // NextChannel()
                        const MyAccount = Object.create(AutoManualJoinObject);
                        MyAccount.ListForJoin = UserState.ListForJoin
                        MyAccount.Session = UserState.Session
                        // MyAccount.ListForJoin = ListForJoin
                        MyAccount.UserName = UserState.UserName
                        MyAccount.ID = UserState.id
                        MyAccount.Next(UserState.ListForJoin)
                    }
                    if (error.errorMessage === 'SESSION_REVOKED'
                        || error.errorMessage == 'AUTH_KEY_UNREGISTERED' || error.errorMessage == "USER_DEACTIVATED_BAN"
                    ) {
                        // console.log('logged out')
                        // Session
                        try {
                            let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ Session: false })
                            // console.log(res)
                            history.push(`/Coin?Status=Free`);

                        } catch (error) {
                            // console.log(error)

                        }
                    } else if (error.errorMessage == 'FLOOD') {
                        // console.log(error.errorMessage)
                        // console.log(error.stack)
                        // console.log(error.seconds)
                        let LimitTime = error.seconds * 1000
                        let FinalLimit = Date.now() + LimitTime
                        try {
                            let error = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: FinalLimit })
                        } catch (error) {
                            // console.log(error)
                        }
                        return error.errorMessage
                    }
                }

            }
            // }


            // console.log(321)
        }



    }
    async function AddTelegramSession(List) {
        try {
            TelegramDB.Data.where({ PhoneNumber: UserState.PhoneNumber }).modify({ ListForJoin: List })
        } catch (error) {
            // console.log(`Failed to add ${Session}: ${error}`);
        }
    }

    const JoinManual = async () => {

        setStartManualJoin(true)
        let response = await ManualJoin(UserState, MyClient, ID)
        console.log(response)
        if (response == 'already join') {
            setSnackbaropen(true)
        } else if (response == 'username null') {
            setSnackbaropen(true)

        } else if (response.status == 'ok') {
            console.log(response)
            //
            if (response.res.code == 200) {
                dispatch(SetJoinCountRedux(response.res.data.user.joinCount))


                if (UserState.FirstTime) {
                    // bottom Sheet true
                    setFirstJoinState(true)
                    let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FirstTime: false })

                }

                try {
                    let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: false })
                } catch (error) {
                    // console.log(error)
                }
            }
        } else if (response == "CHANNELS_TOO_MUCH") {
            // console.log('AccountIsFilled')
            setAccountIsFilled(true)
            try {
                let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: true })
            } catch (error) {
                // console.log(error)
            }
        } else if (response == "FLOOD") {

            let res = await CheckLimit(UserState)
            // console.log(res)
        }



        setStartManualJoin(false)

    }
    const NextChannel = async () => {
        // setLoading(true)
        // console.log( UserState.ListForJoin)
        // const MyAccount = new AutoManualJoin(UserState.Session, UserState.ListForJoin, UserState.User.username, UserState.id);
        // let Next = await MyAccount.Next(UserState.ListForJoin)
        // console.log(Next)
        // setLoading(false)
        // const MyAccount = Object.create(AutoManualJoinObject);
        // MyAccount.ListForJoin = (UserState.ListForJoin)

        // let Next = await MyAccount.Next(UserState.ListForJoin)

        const MyAccount = Object.create(AutoManualJoinObject);
        MyAccount.ListForJoin = UserState.ListForJoin
        MyAccount.Session = UserState.Session
        // MyAccount.ListForJoin = ListForJoin
        MyAccount.UserName = UserState.UserName
        MyAccount.ID = UserState.id
        MyAccount.Next(UserState.ListForJoin)

    }
    const [SnackbarLogout, setSnackbarLogout] = useState(false);

    const Logout = async (Id) => {
        setLogOutState(true)
        try {

            const result = await MyClient.invoke(new Api.auth.LogOut({}));
            // console.log(result);
            const id = await TelegramDB.Data.delete(Id);
            // console.log(id)
            history.push(`/Coin?Status=Free`);

        } catch (error) {
            setSnackbarLogout(true)
            // console.log(`Failed to delete ${Id}: ${error}`);
        }
        setLogOutState(false)
    }
    async function SetAutoJoinDB() {
        try {
            const id = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoinSingle: true });
        } catch (error) {
            // console.log(`Failed to add ${ID}: ${error}`);
        }
    }
    async function UnSetAutoJoinDB() {
        // console.log('stop func')
        try {
            const id = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoinSingle: false });
        } catch (error) {
            // console.log(`Failed to add ${ID}: ${error}`);
        }
    }

    const [LiveCoin, setLiveCoin] = useState(0)
    const [AccountIsFilled, setAccountIsFilled] = useState(false)
    const [SpeedState, setSpeedState] = useState(0)

    const AutoManualJoinObject = {
        // constructor(MySession, List, Username, id, UserId) {
        //     this.MySession = MySession;
        //     this.List = List;
        //     this.Username = Username
        //     this.ID = id
        //     this.UserID = UserId
        // }

        async Join(Client, List) {
            let StatusJoin;
            let ResConnection1
            let JoinTimeout;

            const JoinPromise1 = new Promise((resolve, reject) => {
                resolve(CheckConnection(this.MySession, this.ID));
                clearTimeout(JoinTimeout)
            });
            const JoinPromise2 = new Promise((resolve, reject) => {
                JoinTimeout = setTimeout(resolve, 10000, 'errorConnection');
            });
            ResConnection1 = await Promise.race([JoinPromise1, JoinPromise2])
            // console.log(ResConnection1)
            if (ResConnection1.status == true || ResConnection1.status == undefined && ResConnection1.Client != false) {
                // setConnection(false)
                try {
                    const result = await Client.invoke(
                        new Api.channels.JoinChannel({
                            channel: List[0].username,
                        })
                    );
                    StatusJoin = result;
                } catch (error) {
                    if (error.errorMessage == 'FLOOD') {
                        // console.log(error.errorMessage)
                        // console.log(error.stack)
                        // console.log(error.seconds)
                        let LimitTime = error.seconds * 1000
                        let FinalLimit = Date.now() + LimitTime
                        // console.log(ID)
                        try {
                            // console.log('757')
                            let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: FinalLimit })
                        } catch (error) {
                            // console.log(error)
                        }
                        // try {
                        //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoinSingle: false })
                        // } catch (error) {
                        //     console.log(error)
                        // }

                    }
                    StatusJoin = error
                }
            } else {
                setConnectionLostAutoJoin(true)
                StatusJoin = "connection";
            }
            // console.log(StatusJoin)
            return StatusJoin
        },
        async IsMember(Client, List, ID, User) {

            let IsMemberUser;

            // let ResConnection1 = await CheckConnection(this.MySession, this.ID)
            // const longTask = () => new Promise(resolve =>
            //     setTimeout(() => resolve(ResConnection1), 20000))

            // const timeout = (cb, interval) => () =>
            //     new Promise(resolve => setTimeout(() => cb(resolve), interval))

            // const onTimeout = timeout(resolve => resolve('floodwait'), 2000)
            // Promise.race([longTask, onTimeout])

            let ResConnection1
            let JoinTimeout;

            const JoinPromise1 = new Promise((resolve, reject) => {
                resolve(CheckConnection(this.MySession, this.ID));
                clearTimeout(JoinTimeout)
            });
            const JoinPromise2 = new Promise((resolve, reject) => {
                JoinTimeout = setTimeout(resolve, 10000, 'errorConnection');
            });
            ResConnection1 = await Promise.race([JoinPromise1, JoinPromise2])
            // console.log(ResConnection1)
            if (ResConnection1.status == true || ResConnection1.status == undefined && ResConnection1.Client != false) {
                // setConnection(false)
                try {
                    // console.log(this.Username)
                    if (this.Username === null) {
                        IsMemberUser = 'username null'

                        const IsMember = await Client.invoke(
                            new Api.channels.GetParticipant({
                                channel: List[0].username,
                                participant: User.id.toString(),
                            })
                        );
                        // console.log(IsMember)
                        IsMemberUser = true

                    } else {

                        // console.log(this.UserID)
                        const IsMember = await Client.invoke(
                            new Api.channels.GetParticipant({
                                channel: List[0].username,
                                participant: User.id.toString(),

                                // participant: this.Username,
                            })
                        );
                        // console.log(IsMember)
                        IsMemberUser = true
                    }

                } catch (error) {
                    // console.log(error)
                    if (error.errorMessage == "USER_NOT_PARTICIPANT") {
                        IsMemberUser = false
                    } else if (error.errorMessage == 'FLOOD') {
                        // console.log(error.errorMessage)
                        // console.log(error.stack)
                        // console.log(error.seconds)
                        let LimitTime = error.seconds * 1000
                        let FinalLimit = Date.now() + LimitTime
                        // console.log(ID)
                        try {
                            // console.log('757')
                            let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: FinalLimit })
                        } catch (error) {
                            // console.log(error)
                        }
                        // try {
                        //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoinSingle: false })
                        // } catch (error) {
                        //     console.log(error)
                        // }
                        // Status = { Repeat: true, Status: res.errorMessage }
                        IsMemberUser = error
                    }
                    // console.log(error.errorMessage)


                }
            } else {
                setConnectionLostAutoJoin(true)


                // if (Account.AutoJoin == true) {

                // console.log('Connection error Loop after 2000 ms')
                // Status = { Repeat: true, Status: 'connection' }
                IsMemberUser = "connection"
                // }

            }
            // console.log(IsMemberUser)
            return IsMemberUser

        },
        async Next(List) {
            // console.log(List)
            let NewList = List.filter((item, i) => {
                if (i != 0) {
                    return item
                }
            })
            // console.log(NewList)
            // console.log(Number(this.ID))
            try {
                let res = await TelegramDB.Data.where({ id: Number(this.ID) }).modify({ ListForJoin: NewList })
            } catch (error) {
                // console.log(error)

            }
        },
        async sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        async demo(time) {
            // console.log(time)
            let status = false;
            for (let i = 0; i <= time; i++) {
                let TelegramAccountUpdate = await TelegramDB.Data.toArray()
                let Account
                TelegramAccountUpdate.map(item => {
                    if (item.id == this.ID) {
                        Account = item
                    }
                })
                // console.log(Account.AutoJoin )
                if (Account) {
                    if (Account.AutoJoinSingle == true) {
                        await this.sleep(1000);
                        // console.log(`Waiting ${i} seconds...`);
                        status = true
                    } else {
                        status = false
                        break
                    }
                }


            }
            return status
        },
        async Loop(ID, AccountsLength) {
            // console.log(ID)
            let TelegramAccountUpdate = await TelegramDB.Data.toArray()
            let Account
            let Status = null
            TelegramAccountUpdate.map(item => {
                if (item.id == ID) {
                    Account = item
                }
            })
            if (Account) {
                if (Account.ListForJoin) {
                    // Get List undefined
                    if (Account.ListForJoin.length > 0) {
                        // Get List undefined
                        let ResConnection1
                        let JoinTimeout;

                        const JoinPromise1 = new Promise((resolve, reject) => {
                            resolve(CheckConnection(Account.Session, Account.id));
                            clearTimeout(JoinTimeout)
                        });
                        const JoinPromise2 = new Promise((resolve, reject) => {
                            JoinTimeout = setTimeout(resolve, 10000, 'errorConnection');
                        });
                        ResConnection1 = await Promise.race([JoinPromise1, JoinPromise2])
                        // console.log(ResConnection1)


                        if (ResConnection1.status == true || ResConnection1.status == undefined && ResConnection1.Client != false) {
                            setConnectionLostAutoJoin(false)
                            if (Account.FloodWait == 0 || Account.FloodWait == undefined) {
                                // floodwait
                                const MyClient = ResConnection1.Client
                                if (Account.AutoJoinSingle == true) {
                                    // let speed = 5
                                    let speed = 1
                                    // await MyClient.connect()

                                    let IsMember = await this.IsMember(MyClient, Account.ListForJoin, ID, Account.User)

                                    // console.log(IsMember)
                                    if (Account.SpeedJoin) {
                                        if (Account.SpeedJoin.Status == 'Slowly') {
                                            setSpeedState('Slowly')
                                            // let random = Math.floor(Math.random() * (1 - 0 + 1) + 0)
                                            let random = 0
                                            speed = random + 2
                                        } else if (Account.SpeedJoin.Status == 'Medium') {
                                            setSpeedState('Medium')
                                            // let random = Math.floor(Math.random() * (0 - 0 + 1) + 0)
                                            let random = 0
                                            speed = random + 1
                                        } else if (Account.SpeedJoin.Status == 'Quick') {
                                            setSpeedState('Quick')
                                            // let random = Math.floor(Math.random() * (0 - 0 + 1) + 0)
                                            let random = 0
                                            speed = random + 0
                                        } else if (Account.SpeedJoin.Status == 'Desired') {
                                            if (Account.SpeedJoin.SpeedOfJoin == 1) {
                                                speed = 5
                                            } else if (Account.SpeedJoin.SpeedOfJoin == 2) {
                                                speed = 10
                                            } else if (Account.SpeedJoin.SpeedOfJoin == 3) {
                                                speed = 20
                                            } else if (Account.SpeedJoin.SpeedOfJoin == 4) {
                                                speed = 30
                                            } else if (Account.SpeedJoin.SpeedOfJoin == 5) {
                                                speed = 40
                                            } else if (Account.SpeedJoin.SpeedOfJoin == 6) {
                                                speed = 50
                                            } else if (Account.SpeedJoin.SpeedOfJoin == 7) {
                                                speed = 60
                                            }
                                            console.log(speed)
                                            setSpeedState(speed)
                                        }
                                    } else {
                                        let random = Math.floor(Math.random() * (5 - 0 + 1) + 0)
                                        speed = random + 1
                                    }
                                    // console.log(IsMember)
                                    if (IsMember === false) {
                                        // console.log(speed)

                                        let DelayBeforeJoin = await this.demo(speed);
                                        // console.log(DelayBeforeJoin)

                                        if (DelayBeforeJoin == true) {
                                            let TgChID = Account.ListForJoin[0].tgChannelId
                                            let UserName = Account.ListForJoin[0].username
                                            let BotSeS = Account.BotSession
                                            let Id = Account.ListForJoin[0].Id
                                            let _id = Account.ListForJoin[0]._id
                                            // console.log(UserName)
                                            // console.log(Account.User.username)
                                            // await MyClient.connect()

                                            let res = await this.Join(MyClient, Account.ListForJoin)
                                            //   let res={errorMessage:'FLOOD',seconds:80}
                                            // console.log(res)
                                            // res = { errorMessage: 'FLOOD', seconds: 30 }
                                            // let LimitTime = 30 * 1000
                                            // let FinalLimit = Date.now() + LimitTime
                                            // console.log(ID)
                                            // try {
                                            //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: FinalLimit })
                                            // } catch (error) {
                                            //     console.log(error)
                                            // }
                                            console.log(res)
                                            if (res.chats) {
                                                try {
                                                    let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: false })
                                                } catch (error) {
                                                    // console.log(error)
                                                }
                                                const MakeApiClass = new AllApi();
                                                let JoinResponse = await MakeApiClass.JoinChannel(TgChID, UserName, Token, BotSeS, Id, _id)

                                                // let JoinResponse = await JoinChannel(TgChID, UserName, Token, BotSeS, Id, _id)
                                                console.log(JoinResponse)
                                                if (JoinResponse.code == 309) {
                                                    // console.log('error 309')
                                                    await this.Next(Account.ListForJoin)
                                                    Status = { Repeat: true, Status: 'error 309' }

                                                } else if (JoinResponse.code == 200) {
                                                    let Next = await this.Next(Account.ListForJoin)
                                                    // console.log(JoinResponse.data.user.joinCount)
                                                    dispatch(SetJoinCountRedux(JoinResponse.data.user.joinCount))
                                                    if (Account.AutoJoinSingle == true) {
                                                        // console.log(JoinResponse)
                                                        dispatch(setUserCoin(JoinResponse.data.user.coins))
                                                        let LiveCoinVar = localStorage.getItem(Account.id + "LiveCoin")
                                                        if (LiveCoinVar == 0 || LiveCoinVar == '0') {
                                                            localStorage.setItem(Account.id + "LiveCoin", 2)
                                                        } else {
                                                            localStorage.setItem(Account.id + "LiveCoin", Number(LiveCoinVar) + 2)
                                                        }

                                                        setLiveCoin(localStorage.getItem(Account.id + "LiveCoin"))


                                                        try {
                                                            let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: false })
                                                        } catch (error) {
                                                            // console.log(error)
                                                        }
                                                        Status = { Repeat: true, status: 'ok', Coins: JoinResponse.data.user.coins }

                                                    }
                                                } else {
                                                    Status = { Repeat: true, status: 'join response not ok', Coins: null }

                                                }
                                            } else if (res.errorMessage == 'FLOOD') {
                                                MyClient.destroy();

                                                let Accountlimit;
                                                let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
                                                TelegramAccountUpdatelimit.map(item => {
                                                    if (item.id === Number(ID)) {
                                                        Accountlimit = item
                                                    }
                                                })


                                                let res = await CheckLimit(Accountlimit)
                                                // console.log(res)
                                                Status = { Repeat: false, Status: res.errorMessage }
                                            } else if (res.errorMessage == "FLOOD") {
                                                setAccountIsFilled({ status: true, id: ID })
                                                try {
                                                    let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ CHANNELS_TOO_MUCH: true })
                                                } catch (error) {
                                                    // console.log(error)
                                                }
                                                // try {
                                                //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ AutoJoinSingle: false })
                                                // } catch (error) {
                                                //     console.log(error)
                                                // }
                                                // StopAutoJoin(ID)
                                                Status = { Repeat: false, Status: 'CHANNELS_TOO_MUCH' }
                                            } else if (res == "connection") {
                                                Status = { Repeat: true, Status: "connection" }

                                            } else if (res.errorMessage == "AUTH_KEY_DUPLICATED" || res.errorMessage == "USER_DEACTIVATED_BAN" || res.errorMessage == 'SESSION_REVOKED' || res.errorMessage == "AUTH_KEY_UNREGISTERED") {
                                                // const id = await TelegramDB.Data.delete(ItemID);
                                                let res = await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ Session: false })
                                                // console.log(ItemID)
                                                history.push(`/Coin?Status=Free`);
                                                // Status = { Repeat: false, Status: "account logout" }
                                            } else if (res.errorMessage == "INVITE_REQUEST_SENT") {
                                                Status = { Repeat: true, Status: "INVITE_REQUEST_SENT" }
                                                let Next = await this.Next(Account.ListForJoin)

                                            }
                                            // Status = { Repeat: true, Status: res }


                                        }
                                        // console.log(Status)
                                        // return Status

                                    } else {
                                        console.log(IsMember)
                                        if (IsMember.errorMessage == 'FLOOD') {
                                            MyClient.destroy();

                                            let Accountlimit;
                                            let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
                                            TelegramAccountUpdatelimit.map(item => {
                                                if (item.id === Number(ID)) {
                                                    Accountlimit = item
                                                }
                                            })
                                            let res = await CheckLimit(Accountlimit)
                                            Status = { Repeat: false, Status: IsMember.errorMessage }

                                        } else {

                                            // console.log(IsMember)
                                            let DelayBeforeJoin = await this.demo(1);
                                            if (DelayBeforeJoin) {
                                                // console.log('Next')
                                                await this.Next(Account.ListForJoin)
                                                Status = { Repeat: true, Status: 'is member true' }
                                            }
                                        }






                                    }
                                }
                                // return Status
                            } else {
                                let Accountlimit;
                                let TelegramAccountUpdatelimit = await TelegramDB.Data.toArray()
                                TelegramAccountUpdatelimit.map(item => {
                                    if (item.id === Number(ID)) {
                                        Accountlimit = item
                                    }
                                })


                                let res = await CheckLimit(Accountlimit)
                                // console.log(res)
                                // Status = { Repeat: true, Status: res.errorMessage }


                            }
                        } else {
                            setConnectionLostAutoJoin(true)

                            // setTimeout(() => {
                            if (Account.AutoJoinSingle == true) {

                                // console.log('Connection error Loop after 2000 ms')
                                Status = { Repeat: true, Status: 'connection' }
                                // return Status
                            }
                            // }, 200);
                            // return Status

                        }

                    } else {
                        let res = await GetListFromApi(Account.BotSession, Account.id, Account.Session)
                        // console.log(res)
                        if (res != undefined) {
                            Status = { Repeat: true, Status: 'GetListFromApi' }
                        }

                    }
                } else {
                    let res = await GetListFromApi(Account.BotSession, Account.id, Account.Session)
                    // console.log(res)
                    if (res != undefined) {
                        Status = { Repeat: true, Status: 'GetListFromApi' }
                    }

                }
            }

            // console.log(Status)
            if (Status != null && Status.Repeat == true) {
                if (Status == 'limit') {
                    setFloodWaitState(true)

                }
                // console.log(LiveCoin)

                this.Loop(ID)
                // MyClient.disconnect()
            }





        }
    }

    async function SetAutoJoinDB(ID) {
        try {
            const id = await TelegramDB.Data.where({ id: Number(searchParams.get('id')) }).modify({ AutoJoinSingle: true });
        } catch (error) {
            // console.log(`Failed to add ${'ID'}: ${error}`);
        }
    }
    const [ConnectionLostAutoJoin, setConnectionLostAutoJoin] = useState(false)
    const AutoJoinStart = async (i) => {
        // console.log(i)
        setAutoJoinState(true)




        let Account;
        let TelegramAccountUpdate = await TelegramDB.Data.toArray()
        TelegramAccountUpdate.map(item => {
            if (item.id === Number(searchParams.get('id'))) {
                Account = item
            }
        })
        if (i != 1) {
            // localStorage.setItem(Account.id + "LiveCoin", 0)
        }


        let LimitTime = 60 * 1000
        let FinalLimit = Date.now() + LimitTime

        // console.log(FinalLimit)
        // try {
        //     console.log('1179')
        //     let res = await TelegramDB.Data.where({ id: Number(Account.id) }).modify({ FloodWait: FinalLimit })
        // } catch (error) {
        //     console.log(error)
        // }



        // console.log(Account)
        let Session = Account.Session
        let ListForJoin = Account.ListForJoin
        let UserName = Account.User.username
        let ID = Account.id
        let UserId = Account.User.id
        // console.log(UserId)
        // const MyAccount = new AutoManualJoin(Session, ListForJoin, UserName, ID, UserId);
        const MyAccount = Object.create(AutoManualJoinObject);
        MyAccount.Session = Session
        MyAccount.ListForJoin = ListForJoin
        MyAccount.UserName = UserName
        MyAccount.ID = ID

        if (Account.Session != false) {
            SetAutoJoinDB(Account.id)
            // console.log(Account.FloodWait)

            let ResConnection
            try {
                ResConnection = await CheckConnection(Account.Session, Account.id)
                const longTask = () => new Promise(resolve =>
                    setTimeout(() => resolve(ResConnection), 20000))

                const timeout = (cb, interval) => () =>
                    new Promise(resolve => setTimeout(() => cb(resolve), interval))

                const onTimeout = timeout(resolve => resolve('floodwait'), 2000)
                Promise.race([longTask, onTimeout])
                // console.log(ResConnection)
            } catch (error) {
                ResConnection = error
            }

            // console.log(ResConnection)
            // console.log(Account.AutoJoinSingle)

            if (ResConnection.status == true || ResConnection.status == undefined && ResConnection.Client != false) {
                // localStorage.setItem(Account.id+'AutoJoin',true)
                setConnectionLostAutoJoin(false)


                // dispatch(setAutoJoinLocal({ id: Account.id, Autojoin: true }))
                try {

                    // let LimitTime = 40 * 1000
                    // let FinalLimit = Date.now() + LimitTime
                    // try {
                    //     let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ FloodWait: FinalLimit })
                    // } catch (error) {
                    //     console.log(error)
                    // }
                    MyAccount.Loop(ID, TelegramAccountUpdate.length)

                } catch (error) {
                    // console.log(error)
                }
            } else {

                setConnectionLostAutoJoin(true)
                // console.log('else')
                // // setTimeout(() => {
                // console.log('Connection error after 200 ms')
                // console.log(Account.AutoJoinSingle)
                let MyItem = await TelegramDB.Data.toArray()
                MyItem.map(item => {
                    if (item.id == ID) {
                        if (item.AutoJoinSingle == true) {
                            AutoJoinStart()
                        }

                    }
                })

            }


        }
        // console.log(i)
    }


    const StopAutoJoin = (ID) => {
        // localStorage.removeItem('AutojoinSingle'+ID)
        localStorage.setItem(ID + "LiveCoin", 0)

        // console.log('stop func')
        UnSetAutoJoinDB()
        setAutoJoinState(false)
        // try {
        //     // dispatch(setAutoJoinLocal({ id: ID, AutoJoinSingle: false }))
        // } catch (error) {
        //     console.log(error)
        // }



    }

    const [state, setState] = React.useState({
        opens: false,
        vertical: 'bottom',
        horizontal: 'center',
    });
    const { vertical, horizontal, opens } = state;
    const handleClick = (newState) => {
        setState({ ...newState, opens: true });
    };
    const handleClose1 = () => {
        setState({ ...state, opens: false });
    };
    const [Snackbaropen, setSnackbaropen] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbaropen(false);
    };

    function onDismiss() {
        setTimeout(() => {
            setRemoveNumber(false)
        }, 300);
    }
    const [RadioState, setRadioState] = useState('')
    const [ShowSliderState, setShowSliderState] = useState(false)
    const [RangeValue, setRangeValue] = useState(undefined)
    const [SpeedBottomSheet, setSpeedBottomSheet] = useState(false)
    const [DesiredTime, setDesiredTime] = useState('')
    const [openSpeed, setOpenSpeed] = useState(false);
    const [searchCompleted, setSearchCompleted] = useState(false);

    const drawerBleeding = 80;
    const Ontochmove = (e) => {
        // Add event listener for touchmove event
        let element = document.querySelector('#SwipeableDrawer')
        element.addEventListener("touchmove", handleTouchMove);

        // Event handler function for touchmove event
        function handleTouchMove(event) {
            // Access the touch object from the event
            const touch = event.touches[0];
            // console.log(touch)
            // Retrieve the coordinates of the touch
            const touchX = touch.clientX;
            const touchY = touch.clientY;

            // Do something with the touch coordinates
            // console.log("Touch X:", touchX);
            // console.log("Touch Y:", touchY);
            if (touch.screenY > 200) {
                if (touch.target.className == "SelectJoinSpeedContainer") {
                    setOpenSpeed(false);
                }

            }
        }
    }
    // console.log(RangeValue)
    useEffect(() => {
        //   RadioState
        // console.log(UserState)
        if (UserState) {
            let SpeedFromDB = UserState.SpeedJoin
            // console.log(SpeedFromDB)
            if (SpeedFromDB != undefined) {
                // console.log(SpeedFromDB)
                // console.log(SpeedFromDB.Statu)


                if (RadioState == '') {
                    if (SpeedFromDB.Status == 'Desired') {
                        setShowSliderState(true)

                        setRadioState('Desired')
                        if (SpeedFromDB.SpeedOfJoin == undefined) {
                            setRangeValue('1');

                        } else {
                            setRangeValue(SpeedFromDB.SpeedOfJoin);
                        }


                    } else if (SpeedFromDB.Status == 'Slowly') {
                        setRadioState('Slowly')
                        setShowSliderState(false)

                    } else if (SpeedFromDB.Status == 'Medium') {
                        setRadioState('Medium')
                        setShowSliderState(false)


                    } else if (SpeedFromDB.Status == 'Quick') {
                        setRadioState('Quick')
                        // setRangeValue(undefined)
                        setShowSliderState(false)


                    }
                }

            }
        }
    }, [UserState])

    const handleClickAway = () => {
        setOpenSpeed(false);
        if (UserState) {
            let SpeedFromDB = UserState.SpeedJoin
            // console.log(SpeedFromDB)
            if (SpeedFromDB != undefined) {
                // console.log(SpeedFromDB)
                // console.log(SpeedFromDB.Statu)


                // if (RadioState == '') {
                if (SpeedFromDB.Status == 'Desired') {
                    setShowSliderState(true)

                    setRadioState('Desired')
                    if (SpeedFromDB.SpeedOfJoin == undefined) {
                        setRangeValue('1');

                    } else {
                        setRangeValue(SpeedFromDB.SpeedOfJoin);
                    }


                } else if (SpeedFromDB.Status == 'Slowly') {
                    setRadioState('Slowly')
                    setShowSliderState(false)

                } else if (SpeedFromDB.Status == 'Medium') {
                    setRadioState('Medium')
                    setShowSliderState(false)


                } else if (SpeedFromDB.Status == 'Quick') {
                    setRadioState('Quick')
                    // setRangeValue(undefined)
                    setShowSliderState(false)


                }
                // }

            }
        }
    };
    const toggleDrawer = (newOpen) => () => {
        setOpenSpeed(newOpen);
    };
    const OpenJoinSpeed = () => {
        setOpenSpeed(true)
    }
    const ChangeRangeValue = () => {
        let sliderEl4 = document.querySelector("#range4")
        sliderEl4.addEventListener("input", (event) => {
            let tempSliderValue = event.target.value;
            // console.log(tempSliderValue)
            setRangeValue(tempSliderValue)
            // HandleRadioButton('Desired', tempSliderValue)
            // 5  10  20  30 40 50 60
            // return;
            // HandleRadioButton('Desired', tempSliderValue)

        })

    }
    // console.log(FloodWaitState)
    // console.log(AutoJoinState)
    const HandleRadioButton = async (input, value) => {
        // console.log(input, value)

        let Speed;
        if (input == 'Slowly') {
            // 0-20 + 12


            Speed = 'Slowly'
            setRadioState('Slowly')
            setShowSliderState(false)
        } else if (input == 'Medium') {
            // 0-5 + 7
            Speed = 'Medium'
            setRadioState('Medium')
            setShowSliderState(false)
        } else if (input == 'Quick') {
            // 0-4+3
            Speed = 'Quick'
            setRadioState('Quick')
            setShowSliderState(false)
        } else if (input == 'Desired') {
            // 5  10  20  30 40 50 60
            // console.log(value)

            if (value == undefined || value == 'undefined') {
                value = '1';
                setRangeValue('1');
            } else {
                setRangeValue(value);
            }



            setRadioState('Desired')
            setShowSliderState(true)
        }
        //         console.log(Speed)
        // console.log(input)
        // localStorage.setItem("SpeedUnSaved", Speed);
        // localStorage.setItem("DesiredValueUnSaved", value);


    }
    const [AllCheckBox, setAllCheckBox] = useState(false)
    const ChangeCheckBoxAllAccount = (e) => {
        // console.log(e)
        setAllCheckBox(e.target.checked)
    }
    const SaveDelayTime = async () => {
        // StopAutoJoin()
        // setAutoJoinState(false)

        if (AllCheckBox) {
            let TelegramAccountUpdate = await TelegramDB.Data.toArray()
            for (let index = 0; index < TelegramAccountUpdate.length; index++) {
                if (RadioState == "Desired") {
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(TelegramAccountUpdate[index].id) }).modify({ SpeedJoin: { Status: RadioState, SpeedOfJoin: RangeValue } })

                    } catch (error) {
                        // console.log(error)
                    }
                } else {
                    try {
                        let res = await TelegramDB.Data.where({ id: Number(TelegramAccountUpdate[index].id) }).modify({ SpeedJoin: { Status: RadioState, SpeedOfJoin: undefined } })

                    } catch (error) {
                        // console.log(error)
                    }
                }
            }
        } else {
            if (RadioState == "Desired") {
                try {
                    let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ SpeedJoin: { Status: RadioState, SpeedOfJoin: RangeValue } })

                } catch (error) {
                    // console.log(error)
                }
            } else {
                try {
                    let res = await TelegramDB.Data.where({ id: Number(ID) }).modify({ SpeedJoin: { Status: RadioState, SpeedOfJoin: undefined } })

                } catch (error) {
                    // console.log(error)
                }
            }
        }
        // AutoJoinStart('speed')
        setOpenSpeed(false)
    }
    const Puller = styled(Box)(({ theme }) => ({
        width: 30,
        height: 6,
        backgroundColor: '#E7E7E7',
        borderRadius: 3,
        position: "absolute",
        top: 8,
        left: "calc(50% - 15px)",
    }));

    const StyledBox = styled(Box)(({ theme }) => ({
        backgroundColor: 'white',
    }));
    // console.log(Connection)
    // console.log(UserState)
    return (
        <>
            {
                UserState == null || Connection == null ?
                    <LoadingBig />

                    :

                    <>


                        <div className='HeaderContainerJoin'>
                            <div className='HeaderContainerJoinInner'>
                                <Link to="/Coin?Status=Free" className='HeaderBackJoin'>
                                    <img src={BackImg} className='HeaderBackJoinImg' />
                                </Link>
                                <div className='HeaderJoinDetailsAccount'>
                                    <div className='HeaderJoinDetailsAccountItem1'>
                                        {UserState.ProfilePhoto ?
                                            UserState.ProfilePhoto == "data:text/plain;base64," ?
                                                <div className='ProfilePhotoTextContainer' style={{ "width": "32px", "height": "32px" }}>
                                                    <span className='ProfilePhotoText' style={{ "width": "32px", "height": "32px" }}>
                                                        {UserState.User.firstName.charAt(0).toUpperCase()}
                                                    </span>
                                                </div>

                                                :
                                                <img src={UserState ? UserState.ProfilePhoto : null} className='HeaderJoinDetailsAccountIMG' />
                                            :
                                            <Skeleton variant="circular" width={32} height={32} className='AccountListItemActiveImageSkeleton' />
                                        }

                                    </div>
                                    <div className='HeaderJoinDetailsAccountItem2'>
                                        <div className='HeaderJoinDetailsAccountFirstName'>{UserState ? UserState.User.firstName : null}</div>
                                        <div className='HeaderJoinDetailsAccountPhone'>
                                            {UserState ?
                                                <>
                                                    < ReactCountryFlag
                                                        // key={j}
                                                        countryCode={UserState.CountryName}
                                                        className='FlagPhoneNumber'
                                                        svg
                                                        title={UserState.CountryName}
                                                    />
                                                    +{UserState.User.phone}
                                                </>



                                                :

                                                null}
                                        </div>

                                    </div>
                                </div>
                                {/* Header Logout */}
                                <div className='HeaderJoinLogOut' onClick={() => setRemoveNumber(true)}>
                                                  Logout
                                                    </div>
                                {/* <div className='JoinHeaderMore'>
                                    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleLogOutMenu}>

                                        <img src={More} />


                                    </Button>
                                    <Menu
                                        id="simple-menu"
                                        anchorEl={LogOutMenu}
                                        keepMounted
                                        open={Boolean(LogOutMenu)}
                                        onClose={handleCloseLogOutMenu}
                                    >
                                        <MenuItem onClick={handleCloseLogOutMenu}>

                                            <div className='DropDownShowChannel'>
                                                123
                                                <div className=''>
                                                    {ChannelImage == 'data:text/plain;base64,' ?
                                                        <div className='DropDownImageChannelText' > {ChannelUsername.charAt(0).toUpperCase()}</div>
                                                        : <img className='DropDownImageChannel' src={ChannelImage} />
                                                    }
                                                </div>
                                                <div className='DropDownDesChannel'>
                                                    <div className='DropDownDesTitle'>{ChannelTitle ? ChannelTitle.length > 32 ? ChannelTitle.substr(0, 25) + '...' : ChannelTitle : null}</div>
                                                    <div className='DropDownDesUsername'>@{ChannelUsername ? ChannelUsername : null}</div>
                                                </div>
                                            </div>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={handleCloseLogOutMenu}>
                                            <div className='DropDownShowChannel'>
                                                <div className='DropDownShowChannel' >
                                                    <div className='HeaderJoinLogOut' onClick={() => setRemoveNumber(true)}>
                                                  Logout
                                                    </div>

                                                </div>
                                            </div>
                                        </MenuItem>

                                    </Menu>
                                </div> */}
                                {/* Header Logout */}
                            </div>

                        </div>


                        {/* <GameCoin /> */}
                        {
                            Connection == true && AutoJoinState == false ?
                                <div className='JoinContainer'>
                                    <div className='NoInterNetLogo'>
                                        <img src={NoInternet} />
                                    </div>
                                    <div className='NoInterNetText'>
                                        Please make sure your device is connected to the internet
                                    </div>
                                    <div className='NoInterNetButton'>
                                        <button onClick={() => checkConnection1(UserState.Session)}>
                                            Retry
                                        </button>
                                    </div>

                                </div>
                                :
                                // AccountIsFilled
                                UserState.CHANNELS_TOO_MUCH ?

                                    <div className='JoinContainer'>
                                        <div>
                                            <img src={Filled} />
                                        </div>
                                        <div className='FilledAccountTitle'>
                                            Account is filled
                                        </div>
                                        <div className='FilledAccountDes'>
                                            In Telegram, you can finally become a member of 500 channels or groups
                                        </div>
                                        <div className='JoinButtons'>
                                            <div className='JoinButtonContainer'>
                                                <button className='JoinButtonDeActive' >
                                                    Joining + <img src={DoubleCoinGrey} className='DoubleCoinYellow' />
                                                </button>
                                            </div>
                                            <div className='AutoJoinAndNext'>
                                                <button className='AutoJoinNormalDisable' onClick={() => null}>
                                                    <img src={AutoJoinDisable} /> Auto Join </button>
                                                <button className='NextButtonDisable' onClick={() => null}>
                                                    <img src={skipDisable} /> Next </button>
                                            </div>
                                        </div>
                                    </div>

                                    :
                                    // TooMuchLeft
                                    UserState.TooMuchLeft ?
                                        <div className='JoinContainer'>
                                            <div>
                                                <img src={TooMuchLeftIcon} />
                                            </div>
                                            <div className='TooMuchLeftAccountTitle'>
                                                Too much left

                                                <div className='TooMuchLeftAccountDes'>
                                                    If you leave too many channels/group:
                                                    <ul className='TooMuchLeftAccountUL'>
                                                        <li>
                                                            you will be limited
                                                        </li>
                                                        <li>The quality of your members will decrease. </li>

                                                    </ul>
                                                    This restriction may last from a few hours to
                                                    a few days

                                                </div>
                                            </div>

                                            {/* <div className='JoinButtons'>
                                        <div className='JoinButtonContainer'>
                                            <button className='JoinButtonDeActive' >
                                                Joining + <img src={DoubleCoinGrey} className='DoubleCoinYellow' />
                                            </button>
                                        </div>
                                        <div className='AutoJoinAndNext'>
                                            <button className='AutoJoinNormalDisable' onClick={() => null}>
                                                <img src={AutoJoinDisable} /> Auto Join </button>
                                            <button className='NextButtonDisable' onClick={() => null}>
                                                <img src={skipDisable} /> Next </button>
                                        </div>
                                    </div> */}
                                        </div>
                                        :
                                        AutoJoinState ?
                                            <div className='JoinContainer'>
                                                <div className='JoinHeader'>
                                                    <div className='JoinHeaderImagesContainer'>
                                                        <div className='JoinHeaderImage'>
                                                            {
                                                                // !Loading ?
                                                                // StartManualJoin ?
                                                                <div className='LoadingForBigCoinContainer'>
                                                                    <div className='LoadingForBigCoin'>
                                                                        <img className={
                                                                            SpeedState == 'Slowly' ? 'BigCoin10S' :
                                                                                SpeedState == 'Medium' ? 'BigCoin5S' :
                                                                                    SpeedState == 'Quick' ? 'BigCoin2S' :
                                                                                        SpeedState == '5' ? 'BigCoin2S' :
                                                                                            SpeedState == '10' ? "BigCoin5S" :
                                                                                                SpeedState == '20' ? 'BigCoin5S' :
                                                                                                    SpeedState == '30' ? 'BigCoin10S' :
                                                                                                        SpeedState == '40' ? 'BigCoin10S' :
                                                                                                            SpeedState == '50' ? 'BigCoin10S' :
                                                                                                                SpeedState == '60' ? 'BigCoin10S' : 'BigCoin10S'
                                                                        } src={BigCoin} />
                                                                    </div>
                                                                    <div className='CoinCount'>+{localStorage.getItem(ID + "LiveCoin")}</div>
                                                                </div>
                                                                // :
                                                                // <Skeleton variant="circular" width={127} height={127} animation="wave" />
                                                            }
                                                        </div>
                                                    </div>


                                                    <div>
                                                        <div className='JoinChannelTitle'>
                                                            {
                                                                FloodWaitState ?
                                                                    <div>
                                                                        <div className='AutoJoinFloodWait'>
                                                                            <img src={Danger} className='AutoJoinFloodWaitIcon' />
                                                                            Telegram Join limit
                                                                        </div>
                                                                        <div className='AutoJoinFloodWaitDes'>Waiting to continue again</div>

                                                                    </div>
                                                                    :
                                                                    ConnectionLostAutoJoin ?
                                                                        <div>
                                                                            <div className='AutoJoinFloodWait'>
                                                                                <img src={Danger} className='AutoJoinFloodWaitIcon' />
                                                                                Please connect your device to the internet
                                                                            </div>
                                                                            {/* <div className='AutoJoinFloodWaitDes'>Waiting to continue again</div> */}

                                                                        </div>
                                                                        :
                                                                        'Joining'
                                                            }

                                                        </div>
                                                        <div className='JoinChannelUsername'>
                                                            {UserState.SpeedJoin ?
                                                                <>
                                                                    Join speed:
                                                                    {UserState.SpeedJoin.Status}
                                                                </>
                                                                :
                                                                "Join speed: Normal (recommended)"}
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className='JoinButtons'>
                                                    <div className='JoinButtonContainer'>

                                                        {
                                                            FloodWaitState ?
                                                                <button className='AutoJoinFloodWaitButton'>
                                                                    <span className='FloodWaitButtonTimer'>
                                                                        {Hour > 0 ? Hour + ':' : null}
                                                                        {Minute > 0 ? Minute + ':' : '00' + ':'}
                                                                        {Second > 0 ? Second : '00'}


                                                                    </span>
                                                                    <span className='FloodWaitButtonText'>to continue again</span>
                                                                </button>
                                                                :
                                                                ConnectionLostAutoJoin ?
                                                                    <button className='AutoJoinFloodWaitButton'>
                                                                        <span className='FloodWaitButtonText'> Waiting for network ...</span>
                                                                    </button>

                                                                    :
                                                                    <button className='AutoJoinFloodWaitButton'>
                                                                        <span className='FloodWaitButtonText'>Joining ...</span>
                                                                    </button>
                                                        }

                                                    </div>
                                                    <div className='AutoJoinAndNext'>
                                                        {
                                                            <>
                                                                <button className='JoinSpeed' onClick={() => OpenJoinSpeed()}>
                                                                    <img src={JoinSpeed} className='JoinSpeedIcon' /> Join Speed </button>
                                                                <button className='JoinStop' onClick={() => StopAutoJoin()}>
                                                                    <img src={JoinStop} className='JoinStopIcon' /> Stop joining </button>
                                                            </>

                                                        }

                                                    </div>
                                                </div>


                                            </div>
                                            :
                                            FloodWaitState ?
                                                <div className='JoinContainer'>
                                                    <img src={Limit} />
                                                    <div className='FloodWaitTitle'>
                                                        Telegram Join limit
                                                    </div>
                                                    <div className='FloodWaitDes'>To avoid wasting time, use <span className='FloodWaitDes1'>Auto Join</span></div>
                                                    <div style={{ 'width': '100%' }}>
                                                        <button className='FloodWaitButton'>
                                                            <span className='FloodWaitButtonTimer'>
                                                                {Hour > 0 ? Hour + ':' : null}
                                                                {Minute > 0 ? Minute + ':' : '00' + ':'}
                                                                {Second > 0 ? Second : '00'}


                                                            </span>
                                                            <span className='FloodWaitButtonText'>to continue again</span>
                                                        </button>
                                                    </div>
                                                    <div className='FloodWaitButtonsNextAutoJoin'>
                                                        <button className='AutoJoinInFlood' onClick={() => AutoJoinStart(2)}>
                                                            <img src={AutoJoinInFlood} /> Auto Join</button>
                                                        <button className='NextButtonDisable'
                                                            onClick={() => null}
                                                        >
                                                            <img src={skipDisable} /> Next</button>
                                                    </div>
                                                </div>
                                                :




                                                <div className='JoinContainer'>
                                                    <div className='JoinHeader'>
                                                        <div className='JoinHeaderImagesContainer'>
                                                            <div className='JoinHeaderMore'>
                                                                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClickmenu}>
                                                                    {!Loading ?
                                                                        !StartManualJoin ?
                                                                            <img src={More} />
                                                                            :
                                                                            <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={6} height={36} animation="wave" />

                                                                        :
                                                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={6} height={36} animation="wave" />

                                                                    }

                                                                </Button>
                                                                <Menu
                                                                    id="simple-menu"
                                                                    anchorEl={anchorEl}
                                                                    keepMounted
                                                                    open={Boolean(anchorEl)}
                                                                    onClose={handleClosemenu}
                                                                >
                                                                    <MenuItem onClick={handleClosemenu}>

                                                                        <div className='DropDownShowChannel'>

                                                                            <div className=''>
                                                                                {ChannelImage == 'data:text/plain;base64,' ?
                                                                                    <div className='DropDownImageChannelText' > {ChannelUsername.charAt(0).toUpperCase()}</div>
                                                                                    : <img className='DropDownImageChannel' src={ChannelImage} />
                                                                                }
                                                                            </div>
                                                                            <div className='DropDownDesChannel'>
                                                                                <div className='DropDownDesTitle'>{ChannelTitle ? ChannelTitle.length > 32 ? ChannelTitle.substr(0, 25) + '...' : ChannelTitle : null}</div>
                                                                                <div className='DropDownDesUsername'>@{ChannelUsername ? ChannelUsername : null}</div>
                                                                            </div>
                                                                        </div>
                                                                    </MenuItem>
                                                                    <Divider />
                                                                    <MenuItem className="MenuItemContainer" onClick={handleClosemenu}>
                                                                        <a className='MenuItem' target="_blank" href={`https://telegram.me/${ChannelUsername ? ChannelUsername : null}`}>
                                                                            <OpenInNewOutlinedIcon className="DropDownImage" /> View the channel</a></MenuItem>
                                                                    <MenuItem className="MenuItemContainer" onClick={() => { handleClosemenu(); ShareChannelLink(`https://telegram.me/${ChannelUsername ? ChannelUsername : null}`) }} >
                                                                        <ShareOutlinedIcon className="DropDownImage" /> Share</MenuItem>
                                                                    <MenuItem className="MenuItemContainer" onClick={(e) => { handleClosemenu(); ReportChannelfunc(ChannelUsername ? ChannelUsername : null, ChannelTGID ? ChannelTGID : null, UserState.BotSession) }}>
                                                                        <ReportOutlinedIcon className="DropDownImage" /> Channel report</MenuItem>

                                                                </Menu>
                                                            </div>
                                                            <div className='JoinHeaderImage'>
                                                                {
                                                                    !Loading ?

                                                                        StartManualJoin ?
                                                                            // true ?
                                                                            <>
                                                                                <div className='JoinHeaderImageLoader'></div>
                                                                                {
                                                                                    ChannelImage == 'data:text/plain;base64,' ?
                                                                                        <div className='JoinHeaderImageChannelLoaderText' >
                                                                                            {ChannelUsername.charAt(0).toUpperCase()}
                                                                                        </div>
                                                                                        :

                                                                                        <img className="JoinHeaderImageChannelLoader " src={ChannelImage} />


                                                                                }


                                                                            </>
                                                                            :
                                                                            <>

                                                                                {ChannelImage == 'data:text/plain;base64,' ?
                                                                                    <div className='JoinHeaderImageChannelLoaderText' >
                                                                                        {ChannelUsername.charAt(0).toUpperCase()}
                                                                                    </div>
                                                                                    :
                                                                                    <img className="JoinHeaderImageChannel" src={ChannelImage} />}
                                                                            </>

                                                                        :
                                                                        <Skeleton variant="circular" width={127} height={127} animation="wave" />

                                                                }



                                                            </div>
                                                        </div>


                                                        <div>
                                                            <div className='JoinChannelTitle'>
                                                                {
                                                                    !Loading ?
                                                                        ChannelTitle
                                                                        :
                                                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={127} height={18} animation="wave" />
                                                                }

                                                            </div>
                                                            <div className='JoinChannelUsername'>
                                                                {
                                                                    !Loading ?
                                                                        '@' + ChannelUsername
                                                                        :
                                                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={227} height={18} animation="wave" />
                                                                }
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className='JoinButtons'>
                                                        <div className='JoinButtonContainer'>

                                                            {
                                                                Loading ?
                                                                    <button className='JoinButtonDeActive' >
                                                                        Join + <img src={DoubleCoinGrey} className='DoubleCoinYellow' />
                                                                    </button>
                                                                    :
                                                                    StartManualJoin ?
                                                                        <button className='JoinButtonDeActive' >
                                                                            Joining + <img src={DoubleCoinGrey} className='DoubleCoinYellow' />
                                                                        </button>
                                                                        :
                                                                        <button className='JoinButton' onClick={() => JoinManual()}>
                                                                            Join + <img src={DoubleCoinYellow} className='DoubleCoinYellow' />
                                                                        </button>
                                                            }

                                                        </div>
                                                        <div className='AutoJoinAndNext'>
                                                            {Loading || StartManualJoin ?
                                                                <>
                                                                    <button className='AutoJoinNormalDisable' onClick={() => null}>
                                                                        <img src={AutoJoinDisable} /> Auto Join </button>
                                                                    <button className='NextButtonDisable' onClick={() => null}>
                                                                        <img src={skipDisable} /> Next </button>
                                                                </>
                                                                :
                                                                <>
                                                                    <button className='AutoJoinNormal' onClick={() => AutoJoinStart(3)}>
                                                                        <img src={AutoJoinNormal} /> Auto Join</button>
                                                                    <button className='NextButton'
                                                                        onClick={() => NextChannel()}
                                                                    >
                                                                        <img src={Skip} /> Next</button>
                                                                </>
                                                            }

                                                        </div>
                                                    </div>


                                                </div>
                        }

                        <BottomSheet onDismiss={onDismiss} open={RemoveNumber}>
                            <div className='RegisterBeforeBottomSheet'>
                                <div className='RegisterBeforeBottomSheetHeader'>
                                    <div className='RegisterBeforeBottomSheetHeaderText1'>
                                        <img src={DangerBottomSheet} className='DangerBottomSheet' />
                                        Log out
                                    </div>
                                </div>
                                <div className='RegisterBeforeBottomSheetBody'>


                                    Are you sure you want to log out?
                                    {/* Are you sure to log out of the account? */}
                                    {/* <span className='UserNameChannelBold'>+{UserState.PhoneNumber}</span>
                        from the list? */}

                                </div>
                                <div className='RegisterBeforeBottomSheetFooter'>
                                    <button className='BottomSheetCancelButton' onClick={() => setRemoveNumber(false)}>Cancel</button>
                                    <button className='BottomSheetLoginButton' onClick={() => Logout(UserState.id)}>
                                        {
                                            LogOutState ? <div className="loadminijoin"></div>
                                                : "Log out"
                                        }

                                    </button>
                                </div>
                            </div>
                        </BottomSheet>
                        <Snackbar
                            open={Snackbaropen}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            message="already joined"

                        />
                        <Snackbar
                            open={SnackbarLogout}
                            autoHideDuration={3000}
                            onClose={handleClose}
                            message="Something went wrong! Please try again."

                        />
                        <SwipeableDrawer
                            // onClick={(e)=>handleClickAway(e)}
                            id="SwipeableDrawer"
                            // container={container}
                            anchor="bottom"
                            open={openSpeed}
                            onClose={() => {
                                toggleDrawer(false);
                            }}
                            onDismiss={() => {
                                setOpenSpeed(false)
                            }}
                            onSwipe={() => {
                                setOpenSpeed(false)
                            }}
                            onOpen={toggleDrawer(true)}
                            swipeAreaWidth={drawerBleeding}
                            disableSwipeToOpen={!searchCompleted}
                            SwipToClose={true}
                            SwipeableDrawer={true}
                            ModalProps={{
                                keepMounted: false
                            }}
                            disableDiscovery={true}
                            onTouchMove={(e) => {
                                Ontochmove(e)
                            }}
                            draggable={true}
                        >
                            <StyledBox
                                sx={{
                                    position: "absolute",
                                    top: -drawerBleeding,
                                    borderTopLeftRadius: 8,
                                    borderTopRightRadius: 8,
                                    visibility: "visible",
                                    right: 0,
                                    left: 0,
                                }}
                            >
                                <Puller
                                    // onTouchMove={(e) => {
                                    //   Ontochmove(e)
                                    //  }}
                                    onTouchMove={(e) => {
                                        Ontochmove(e)
                                    }}
                                />

                            </StyledBox>
                            <StyledBox
                                sx={{
                                    px: 2,
                                    pb: 2,
                                    height: "100%",
                                    overflow: "auto",
                                }}
                            ></StyledBox>

                            <ClickAwayListener onClickAway={handleClickAway}>
                                <div className='SelectJoinSpeedContainer' >
                                    <div className='SelectJoinSpeedTitle'>
                                        Change speed
                                    </div>
                                    <div className='SelectJoinSpeedDes'>
                                        Attention: As the join speed increases, the probability of your account being blocked by Telegram increases
                                    </div>
                                    <div className='SelectJoinSpeedItems'>
                                        <div >
                                            <p className='ParaRadio' onClick={(e) => HandleRadioButton('Slowly')}>
                                                <input type="radio" id="Slowly" onChange={(e) => HandleRadioButton('Slowly')} name="radio-group" checked={RadioState == 'Slowly' ? true : null} />
                                                <label htmlFor="Slowly" >Slowly</label>
                                            </p>
                                            <p className='ParaRadio' onClick={(e) => HandleRadioButton('Medium')}>
                                                <input type="radio" id="Medium" onChange={(e) => HandleRadioButton('Medium')} name="radio-group" checked={RadioState == 'Medium' ? true : null} />
                                                <label htmlFor="Medium">Medium (default)</label>
                                            </p>
                                            <p className='ParaRadio' onClick={(e) => HandleRadioButton('Quick')}>
                                                <input type="radio" id="Quick" onChange={(e) => HandleRadioButton('Quick')} name="radio-group" checked={RadioState == 'Quick' ? true : null} />
                                                <label htmlFor="Quick" >Quick</label>
                                            </p>
                                            <p className='ParaRadio' onClick={(e) => HandleRadioButton('Desired')}>
                                                <input type="radio" id="Desired" onChange={(e) => HandleRadioButton('Desired')} name="radio-group" checked={RadioState == 'Desired' ? true : null} />
                                                <label htmlFor="Desired" >Desired</label>
                                            </p>
                                        </div>


                                    </div>
                                    {
                                        ShowSliderState ?
                                            <div className='SlideForJoinSpeed'>

                                                <div className="wrapper">
                                                    <div className="content">

                                                        <div className="range">
                                                            <div className="range-slider">
                                                                <label className='LAbleSlider' htmlFor="range">
                                                                    Time between each membership:
                                                                    {RangeValue == "7" ? " 60" : RangeValue == "6" ? " 50" : RangeValue == "5" ? " 40" : RangeValue == "4" ? " 30" : RangeValue == "3" ? " 20" : RangeValue == "2" ? " 10" : RangeValue == "1" ? " 5" : null}</label>
                                                                <br />
                                                                <input
                                                                    type="range"
                                                                    min="1"
                                                                    max="7"
                                                                    value={RangeValue}
                                                                    className="range-input"
                                                                    id="range4"
                                                                    step="1"
                                                                    key="1"
                                                                    onInput={ChangeRangeValue}
                                                                    onChange={ChangeRangeValue}

                                                                />


                                                                <div className="sliderticks">
                                                                    <span onClick={(e) => setRangeValue('1')}></span>
                                                                    <span onClick={(e) => setRangeValue('2')}></span>
                                                                    <span onClick={(e) => setRangeValue('3')}></span>

                                                                    <span onClick={(e) => setRangeValue('4')}></span>
                                                                    <span onClick={(e) => setRangeValue('5')}></span>

                                                                    <span onClick={(e) => setRangeValue('6')}></span>

                                                                    <span onClick={(e) => setRangeValue('7')}></span>

                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>


                                            </div> : null
                                    }
                                    <div className='SelectJoinSpeedAll'>
                                        <FormControlLabel control={<Checkbox defaultChecked={AllCheckBox ? true : false} style={{ "font-size": "12px" }} onChange={(e) => ChangeCheckBoxAllAccount(e)} />} label="Speed change for all accounts" />

                                    </div>
                                    <div className='SelectJoinSpeedButtons'>


                                        <button className='SelectJoinSpeedButtonsSecondry' onClick={handleClickAway} >Cancel</button>
                                        <button className='SelectJoinSpeedButtonsPrimary'
                                            onClick={() => SaveDelayTime()}
                                        >Save</button>
                                    </div>
                                </div>
                            </ClickAwayListener>

                        </SwipeableDrawer>

                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={opens}
                            onClose={handleClose1}
                            // message={` Password changed`}
                            key={vertical + horizontal}
                            autoHideDuration={3000}
                            message={<span id="client-snackbar"><WifiOffIcon className='WifiIcon' /> No internet connection</span>}

                        />

                        <Snackbar
                            anchorOrigin={{ vertical, horizontal }}
                            open={ReportSnackbar}
                            onClose={handleClose1}
                            message={'Thank you for your report'}
                            // key={vertical + horizontal}
                            autoHideDuration={2000}
                        // message={<span id="client-snackbar"><WifiOffIcon className='WifiIcon' /> No internet connection</span>
                        // }

                        />

                        <BottomSheet onDismiss={onDismissFirstJoin} open={FirstJoinState}>
                            <div className='RegisterBeforeBottomSheet'>
                                <div className='FirstJoinHeader'>
                                    <div className='FirstTimeJoinHeader1'>
                                        <div className='WarningFirstTimeJoinHeader1'>Warning</div>
                                        <img src={FirstJoinImg} className='FirstJoinImag' />

                                    </div>
                                </div>
                                <div className='RegisterBeforeBottomSheetBody'>


                                    If you left any channel or group, 4 coins will be deducted from your account for each exit, and your account will be blocked if you repeat it.

                                </div>
                                <div className='RegisterBeforeBottomSheetFooter'>
                                    {/* <button className='BottomSheetCancelButton' onClick={() => setRemoveNumber(false)}>Cancel</button> */}
                                    <button className='BottomSheetFirstJoinButton' onClick={() => setFirstJoinState(false)}>
                                        Got it

                                    </button>
                                </div>
                            </div>
                        </BottomSheet>
                    </>
            }
        </>

    )
}

export default AutoManualJoin