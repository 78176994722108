import React from 'react';
import './App.css';
import Auth from './Auth/Auth'
import Login from './Auth/Login'
import Register from './Auth/Register'
import RegisterGetPassword from './Auth/RegisterGetPassword'
import Home from './Home/Home'
import GetPhoneNumber from './Auth/GetPhoneNumber'
import CheckVerificationCode from './Auth/CheckVerificationCode'
import ForgetPassword from './Auth/ForgetPassword'
import ForgetPasswordGetCode from './Auth/ForgetPasswordGetCode'
import ChangePassword from './Auth/ChangePassword';
import Coin from './Coin/Coin'
import GetLinkUsername from './Home/GetLinkUsername'
import GetUsernameView from './Home/GetUsernameView'
import Notice from './Home/Notice'
import TransferHistory from './Coin/TransferHistory';
import GiftCode from './Coin/GiftCode'
import Profile from './Profile/Profile'
import AccountInformation from './Profile/AccountInformation'
import Payments from './Profile/Payments'
import LogOut from './Profile/LogOut'
import DeleteAccount from './Profile/DeleteAccount'
import AddNumber from './Profile/AddNumber'
import AddNumberVerify from './Profile/AddNumberVerify'
import EditNumber from './Profile/EditNumber'
import EditNumberVerify from './Profile/EditNumberVerify'
import Privacy from './Profile/Privacy'
import SetPassword from './Profile/SetPassword'
import GetCheckPassword from './Profile/GetCheckPassword'
import GetChangePassword from './Profile/GetChangePassword'
import Orders from './MyOrder/Orders'
import OrderDetailsSingle from './MyOrder/OrderDetailsSingle'
import OrderDetailsAll from './MyOrder/OrderDetailsAll'
import Game from './Game/Game'
import AllReward from './Game/AllReward/AllReward'

import SellingPhoneNumbers from './Sale/SellingPhoneNumbers'
import SaleHistory from './Sale/SaleHistory'
import VerificationCode from './Sale/VerificationCode'
import EditProfile from './Profile/EditProfile';
import SetName from './Profile/SetName/SetName';
import "./Public/fonts/font.css"
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoginTelegram from './TelegramLogin/LoginTelegram'
import VerifyCode from './TelegramLogin/VerifyCode'
import JoinBot from './TelegramLogin/JoinBot'
import AutoManualJoin from './Coin/AutoManualJoin';
import Update from './BottomSheet/Update'

import { useState } from 'react';
import { useEffect } from 'react';



import SnackBarEndLeague from './Game/GlobalGameComponent/SnackBarEndLeague'

function App() {

const [Mode, setMode] = useState('light')
useEffect(() => {
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
// console.log(mediaQuery.matches)
if(mediaQuery.matches){
  setMode('dark')
}
}, [])

  // let AuthState = GetCookie('Token')
  // const history = useHistory();

  // useEffect(() => {
  //   if (AuthState == null || AuthState == 'null') {
  //     history.push(`/`);
  //   }
  // }, [AuthState])


// console.log(Mode)
  return (
    <div className={"App " + Mode} >

      <Router>
        <Switch>

          <Route exact path="/" component={Auth} />
          <Route path="/Login" component={Login} />
          <Route path="/Register" component={Register} />
          <Route path="/RegisterGetPassword" component={RegisterGetPassword} />
          <Route path="/Home" component={Home} />
          <Route path="/GetPhoneNumber" component={GetPhoneNumber} />
          <Route path="/CheckVerificationCode" component={CheckVerificationCode} />
          <Route path="/ForgetPassword" component={ForgetPassword} />
          <Route path="/ForgetPasswordGetCode" component={ForgetPasswordGetCode} />
          <Route path="/ChangePassword" component={ChangePassword} />
          <Route path="/Coin" component={Coin} />
          <Route path="/LoginTelegram" component={LoginTelegram} />
          <Route path="/VerifyCode" component={VerifyCode} />
          <Route path="/JoinBot" component={JoinBot} />
          <Route path="/AutoManualJoin" component={AutoManualJoin} />
          <Route path="/Notice" component={Notice} />
          <Route path="/GetLinkUsername" component={GetLinkUsername} />
          <Route path="/GetUsernameView" component={GetUsernameView} />

          <Route path="/TransferHistory" component={TransferHistory} />
          <Route path="/GiftCode" component={GiftCode} />
          <Route path="/Profile" component={Profile} />
          <Route path="/AccountInformation" component={AccountInformation} />
          <Route path="/Payments" component={Payments} />
          <Route path="/LogOut" component={LogOut} />
          <Route path="/DeleteAccount" component={DeleteAccount} />
          <Route path="/AddNumber" component={AddNumber} />
          <Route path="/AddNumberVerify" component={AddNumberVerify} />
          <Route path="/EditNumber" component={EditNumber} />
          <Route path="/EditNumberVerify" component={EditNumberVerify} />
          <Route path="/EditProfile" component={EditProfile} />
          <Route path="/SetName" component={SetName} />
          
          
          <Route path="/Orders" component={Orders} />
          <Route path="/OrderDetailsSingle" component={OrderDetailsSingle} />
          <Route path="/OrderDetailsAll" component={OrderDetailsAll} />
          <Route path="/Privacy" component={Privacy} />
          <Route path="/SetPassword" component={SetPassword} />
          <Route path="/GetCheckPassword" component={GetCheckPassword} />
          <Route path="/GetChangePassword" component={GetChangePassword} />
          {/* <Route path="/Game" component={Game} /> */}
          <Route path="/AllReward" component={AllReward} />
        
          
          <Route path="/SellingPhoneNumbers" component={SellingPhoneNumbers} />
          <Route path="/SaleHistory" component={SaleHistory} />
          <Route path="/VerificationCode" component={VerificationCode} />
          

        </Switch>
      </Router>
      <Update />
      <SnackBarEndLeague />
    </div>
  );
}

export default App;
